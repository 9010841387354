import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/serializers"
import ContactForm from "../components/contactForm"

export const query = graphql`
  query($Slug: String) {
    sanitySubPages(slug: { current: { eq: $Slug } }) {
      title
      _rawBody
      seo {
        seo_title
        meta_description
      }
    }
    allSanityServices {
      edges {
        node {
          title
          subtitle
          slug {
            current
          }
        }
      }
    }
  }
`

const subPage = props => {
  return (
    <Layout>
      {props.data.sanitySubPages.seo && (
        <SEO
          title={props.data.sanitySubPages.seo.seo_title}
          metaDescription={props.data.sanitySubPages.seo.meta_description}
          seoTitle={props.data.sanitySubPages.seo.seo_title}
        />
      )}

      <section className="insight_subpage subpage">
        <div className="text_content">
          <h1>{props.data.sanitySubPages.title}</h1>
          <div>
            <BlockContent
              className={props.data.sanitySubPages.templateList}
              blocks={props.data.sanitySubPages._rawBody}
              projectId="ppd47c0u"
              dataset="production"
              serializers={serializers}
            />
          </div>
        </div>
      </section>
      <ContactForm></ContactForm>
    </Layout>
  )
}

export default subPage
